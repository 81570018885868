exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ashtrays-index-js": () => import("./../../../src/pages/ashtrays/index.js" /* webpackChunkName: "component---src-pages-ashtrays-index-js" */),
  "component---src-pages-ashtrays-mdx-slug-js": () => import("./../../../src/pages/ashtrays/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-ashtrays-mdx-slug-js" */),
  "component---src-pages-cardgallery-js": () => import("./../../../src/pages/cardgallery.js" /* webpackChunkName: "component---src-pages-cardgallery-js" */),
  "component---src-pages-charms-index-js": () => import("./../../../src/pages/charms/index.js" /* webpackChunkName: "component---src-pages-charms-index-js" */),
  "component---src-pages-charms-mdx-slug-js": () => import("./../../../src/pages/charms/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-charms-mdx-slug-js" */),
  "component---src-pages-coasters-index-js": () => import("./../../../src/pages/coasters/index.js" /* webpackChunkName: "component---src-pages-coasters-index-js" */),
  "component---src-pages-coasters-mdx-slug-js": () => import("./../../../src/pages/coasters/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-coasters-mdx-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-favors-index-js": () => import("./../../../src/pages/favors/index.js" /* webpackChunkName: "component---src-pages-favors-index-js" */),
  "component---src-pages-favors-mdx-slug-js": () => import("./../../../src/pages/favors/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-favors-mdx-slug-js" */),
  "component---src-pages-holders-index-js": () => import("./../../../src/pages/holders/index.js" /* webpackChunkName: "component---src-pages-holders-index-js" */),
  "component---src-pages-holders-mdx-slug-js": () => import("./../../../src/pages/holders/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-holders-mdx-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jewelry-index-js": () => import("./../../../src/pages/jewelry/index.js" /* webpackChunkName: "component---src-pages-jewelry-index-js" */),
  "component---src-pages-jewelry-mdx-slug-js": () => import("./../../../src/pages/jewelry/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-jewelry-mdx-slug-js" */),
  "component---src-pages-ornaments-index-js": () => import("./../../../src/pages/ornaments/index.js" /* webpackChunkName: "component---src-pages-ornaments-index-js" */),
  "component---src-pages-ornaments-mdx-slug-js": () => import("./../../../src/pages/ornaments/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-ornaments-mdx-slug-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-trays-index-js": () => import("./../../../src/pages/trays/index.js" /* webpackChunkName: "component---src-pages-trays-index-js" */),
  "component---src-pages-trays-mdx-slug-js": () => import("./../../../src/pages/trays/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-trays-mdx-slug-js" */)
}

